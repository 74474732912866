import React from 'react'

/**
 * React component that renders the main navbar
 */
export class NavBar extends React.Component {

    addEnvVisual() {
        return <p>{process.env.REACT_APP_ENV !== 'ENV_PROD' ? `Environnement : ${process.env.REACT_APP_ENV}` : null}</p>;
    }

    /**
     * Render function
     * @returns {*}
     */
    render() {
        return <div id={"navbar"}>
            {this.addEnvVisual()}
            <div>
                {this.props.user && this.props.isAuthenticated ? <p>Bonjour, {this.props.user.name}</p> : null}
                <button
                    onClick={this.props.authButtonMethod}>{this.props.isAuthenticated ? "Déconnexion" : "Se connecter"}</button>
            </div>
        </div>
    }
}