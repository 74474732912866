import {
    deleteManufCorrespondence,
    getEmptyManufacturersRequest,
    updateEmptyManufacturersRequest
} from "../api/manufacturersApi";

export const GET_EMPTY_MANUFACTURERS_REQUEST = "GET_EMPTY_MANUFACTURERS_REQUEST";
export const GET_EMPTY_MANUFACTURERS_SUCCESS = "GET_EMPTY_MANUFACTURERS_SUCCESS";
export const GET_EMPTY_MANUFACTURERS_ERROR = "GET_EMPTY_MANUFACTURERS_ERROR";

export const UPDATE_MANUFACTURERS_REQUEST = "UPDATE_MANUFACTURERS_REQUEST";
export const UPDATE_MANUFACTURERS_SUCCESS = "UPDATE_MANUFACTURERS_SUCCESS";
export const UPDATE_MANUFACTURERS_ERROR = "UPDATE_MANUFACTURERS_ERROR";

export const DELETE_MANUFACTURERS_REQUEST = "DELETE_MANUFACTURERS_REQUEST";
export const DELETE_MANUFACTURERS_SUCCESS = "DELETE_MANUFACTURERS_SUCCESS";
export const DELETE_MANUFACTURERS_ERROR = "DELETE_MANUFACTURERS_ERROR";

/**
 * Action that requests all empty and new manufacturer correspondences
 * @returns {Function}
 */
export const getEmptyManufacturers = () => async dispatch => {
    try {
        dispatch({type: GET_EMPTY_MANUFACTURERS_REQUEST});

        let { mans, all_corr} = await getEmptyManufacturersRequest();
        dispatch({
            type: GET_EMPTY_MANUFACTURERS_SUCCESS,
            payload: {
                manufacturers: mans,
                allCorrespondences: all_corr
            }
        })
    } catch (e) {
        dispatch({type: GET_EMPTY_MANUFACTURERS_ERROR, payload: e.message});
    }
};

/**
 * Action that updates one or more manufacturer correspondence
 * @param modifications Modifications to make to the correspondences
 * @returns {Function}
 */
export const updateEmptyManufacturers = (modifications) => async dispatch => {
    try {
        if (modifications.length > 0) {
            dispatch({type: UPDATE_MANUFACTURERS_REQUEST});
            await updateEmptyManufacturersRequest(modifications);
            dispatch({
                type: UPDATE_MANUFACTURERS_SUCCESS,
                payload: modifications
            });
        }
    } catch (e) {
        dispatch({type: UPDATE_MANUFACTURERS_ERROR, payload: e.message});
    }
};

/**
 * Action that deletes a correspondence
 * @param idCorr Id of the correspondence to delete
 * @returns {Function}
 */
export const deleteManufacturerCorr = (idCorr) => async (dispatch, getState) => {
    try {
        dispatch({type: DELETE_MANUFACTURERS_REQUEST});
        await deleteManufCorrespondence(idCorr);

        let newCorrespondences = [...getState().manufacturers.allCorrespondences];

        let index = newCorrespondences.findIndex(m => m.id === idCorr);
        if (index > -1) {
            newCorrespondences.splice(index, 1);
        }

        dispatch({
            type: DELETE_MANUFACTURERS_SUCCESS,
            payload: newCorrespondences
        });
    } catch (e) {
        dispatch({type: DELETE_MANUFACTURERS_ERROR, payload: e.message});
    }
};