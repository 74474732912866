export const SAVE_SEARCH_REQUEST = "SAVE_SEARCH_REQUEST";
export const SAVE_SEARCH_SUCCESS = "SAVE_SEARCH_SUCCESS";
export const SAVE_SEARCH_ERROR = "SAVE_SEARCH_ERROR";

/**
 * Action that saves the last search that was made when changing views
 * @param searchTerm the term that was searched
 * @param checkChoice the field that was checked
 * @returns {Function}
 */
export const saveSearch = (searchTerm, checkChoice) => async dispatch => {
    try {
        dispatch({type: SAVE_SEARCH_REQUEST});
        dispatch({
            type: SAVE_SEARCH_SUCCESS,
            payload: {searchTerm, checkChoice}
        })
    } catch (e) {
        dispatch({type: SAVE_SEARCH_ERROR, payload: e.message});
    }
};
