import React from 'react';
import './index.css';
import './Pricebook.css';
import {BrowserRouter as Router} from 'react-router-dom'
import {Routes} from './components/Routes'
import {NavBar} from './components/NavBar'

import config from './config';
import {UserAgentApplication} from 'msal';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.userAgentApplication = new UserAgentApplication({
            auth: {
                clientId: config.appId,
                authority: config.authority,
                redirectUri: config.redirectUri
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true
            }
        });

        let user = this.userAgentApplication.getAccount();
        this.state = {
            isAuthenticated: (user !== null),
            user: {},
            error: null
        };

        if (user) {
            this.getUserProfile();
        }
    }

    async login() {
        try {
            await this.userAgentApplication.loginPopup(
                {
                    scopes: config.scopes,
                    prompt: "select_account"
                });
            await this.getUserProfile();
        } catch (err) {
            var error = {};

            if (typeof (err) === 'string') {
                var errParts = err.split('|');
                error = errParts.length > 1 ?
                    {message: errParts[1], debug: errParts[0]} :
                    {message: err};
            } else {
                error = {
                    message: err.message,
                    debug: JSON.stringify(err)
                };
            }

            this.setState({
                isAuthenticated: false,
                user: {},
                error: error
            });
        }
    }

    logout() {
        this.userAgentApplication.logout();
    }

    async getUserProfile() {
        try {
            // Get the access token silently
            // If the cache contains a non-expired token, this function
            // will just return the cached token. Otherwise, it will
            // make a request to the Azure OAuth endpoint to get a token

            var accessToken = await this.userAgentApplication.acquireTokenSilent({
                scopes: config.scopes
            });
            if (accessToken) {
                this.setState({
                    isAuthenticated: true,
                    user: accessToken.account
                });
            }
        } catch (err) {
            var errParts = err.split('|');
            this.setState({
                isAuthenticated: false,
                user: {},
                error: {message: errParts[1], debug: errParts[0]}
            });
        }
    }

    render() {
        return (
            <Router>
                <NavBar isAuthenticated={this.state.isAuthenticated}
                        authButtonMethod={this.state.isAuthenticated ? this.logout.bind(this) : this.login.bind(this)}
                        user={this.state.user}/>
                <Routes isAuthenticated={this.state.isAuthenticated}
                        user={this.state.user}/>
            </Router>
        );
    }
}

export default App;
