import {baseUrl} from "../utils/constants";
import axios from "axios";
import {getToken} from "./authApi";

/**
 * Sends a request to the API to get all Bids
 * @returns {Object}
 */
export const getBidsRequest = async () => {
    let token = await getToken();
    const res = await axios.get(baseUrl + "/bid", {
        headers: {
            "Authorization": token
        }
    });
    return res.data;
};

/**
 * Sends a request to the API to add a Bid
 * @param data Excel file data
 * @returns {Object}
 */
export const addBidRequest = async (data) => {
    let token = await getToken();
    const res = await axios.post(baseUrl + "/bid", data, {
        headers: {
            "Authorization": token
        }
    });
    return res.data;
};

/**
 * Sends a request to the API to remove a bid
 * @param bid_id Id of the Bid to remove
 * @returns {Promise<void>}
 */
export const removeBidRequest = async (bid_id) => {
    let token = await getToken();
    await axios.delete(baseUrl + "/bid/" + bid_id, {
        headers: {
            "Authorization": token
        }
    });
};

