export const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const XML_INGRAM_URL = baseUrl + '/proxyIngram/';
export const INGRAM_PROVIDER_NAME = "Ingram Micro";

export const XML_SYNNEX_URL = baseUrl + '/proxySynnex/';
export const SYNNEX_PROVIDER_NAME = "Synnex";

export const XML_TECH_URL = baseUrl + '/proxyTech/';
export const TECH_PROVIDER_NAME = "Tech Data";

export const XML_DH_URL = baseUrl + '/proxyDH/';
export const DH_PROVIDER_NAME = 'D&H';

export const SECRET_KEY = '71@&A62a!T$9ma7gXuPyXRO7&xreH0fDfNG';
export const API_UNAME = "catalog";
export const API_PASS = "kFI2!1^3d&cUBD^IJbFaQRb5a%QThwLfl6b";

export const NETSUITE_DOMAIN = process.env.REACT_APP_NETSUITE_DOMAIN;
