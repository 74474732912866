import {
    DELETE_MANUFACTURERS_ERROR,
    DELETE_MANUFACTURERS_REQUEST,
    DELETE_MANUFACTURERS_SUCCESS,
    GET_EMPTY_MANUFACTURERS_ERROR,
    GET_EMPTY_MANUFACTURERS_REQUEST,
    GET_EMPTY_MANUFACTURERS_SUCCESS,
    UPDATE_MANUFACTURERS_ERROR,
    UPDATE_MANUFACTURERS_REQUEST,
    UPDATE_MANUFACTURERS_SUCCESS
} from "../actions/manufacturerActions";

const defaultState = {
    allManufacturers: null,
    isLoading: false,
    error: false,
    isDoneLoading: false,
    activeHeader: null,
    allCorrespondences: null
};

export default (state = {...defaultState}, action) => {
    switch (action.type) {
        case GET_EMPTY_MANUFACTURERS_REQUEST:
        case DELETE_MANUFACTURERS_REQUEST:
        case UPDATE_MANUFACTURERS_REQUEST:
            return {...state, isLoading: true, isDoneLoading: false, error: false};
        case GET_EMPTY_MANUFACTURERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDoneLoading: true,
                allManufacturers: action.payload.manufacturers,
                allCorrespondences: action.payload.allCorrespondences
            };
        case UPDATE_MANUFACTURERS_SUCCESS:
            let updatedAllCorrespondences = [...state.allCorrespondences];
            const allManufacturers = state.allManufacturers;

            let idsModified = action.payload.map(g => ({
                correspondenceId : parseInt(g.man_corr_id),
                manufacturerId: parseInt(g.man_id)
            }));

            idsModified.forEach(couple => {
                let index = updatedAllCorrespondences.findIndex(c => c.id === couple.correspondenceId);
                let manufacturerCode = allManufacturers.find(m => m.id === couple.manufacturerId);

                if (index > -1 && manufacturerCode) {
                    let correspondence = {...updatedAllCorrespondences[index], man_id: couple.manufacturerId, man_code: manufacturerCode, is_new: false};
                    updatedAllCorrespondences.splice(index, 1, correspondence);
                }
            });

            return {...state, allCorrespondences: updatedAllCorrespondences, isLoading: false, isDoneLoading: true};
        case DELETE_MANUFACTURERS_SUCCESS:
            return {...state, isLoading: false, error: false, isDoneLoading: true, allCorrespondences: action.payload};
        case GET_EMPTY_MANUFACTURERS_ERROR:
        case DELETE_MANUFACTURERS_ERROR:
        case UPDATE_MANUFACTURERS_ERROR:
            return {...state, isLoading: false, isDoneLoading: true, error: action.payload};
        default:
            return state
    }
}