import React from 'react';

/**
 * Loading wheel react component
 */
class Loading extends React.Component {

    /**
     * Render function
     * @returns {*}
     */
    render() {
        return <div className={"load-container"}>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    }
}

export default Loading;