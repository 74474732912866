import {
    GET_PRODUCT_DETAILS_ERROR,
    GET_PRODUCT_DETAILS_REQUEST,
    GET_PRODUCT_DETAILS_SUCCESS,
    SEARCH_FOR_PRODUCTS_ERROR,
    SEARCH_FOR_PRODUCTS_REQUEST,
    SEARCH_FOR_PRODUCTS_SUCCESS,
    SEND_PNA_ERROR,
    SEND_PNA_REQUEST,
    SEND_PNA_SUCCESS
} from "../actions/productActions";


const defaultState = {
    allProducts: null,
    activeProducts: null,
    productDetails: null,
    isLoading: false,
    error: false,
    isDoneLoading: true,
    pnaLoading: [],
    updated: []
};

/**
 * Function that manages the state of loading PNA requests for a sku
 * @param loading the current loading object
 * @param sku sku to manage
 * @returns {*[]}
 */
const manageLoading = (loading, sku) => {
    let load = [...loading];
    let indexToRemove = load.findIndex(s => s.sku === sku);
    if (indexToRemove > -1) {
        load[indexToRemove].loading = !load[indexToRemove].loading;
    } else {
        load.push({sku: sku, loading: true})
    }
    return load;
};

export default (state = {...defaultState}, action) => {
    switch (action.type) {
        case SEARCH_FOR_PRODUCTS_REQUEST:
        case GET_PRODUCT_DETAILS_REQUEST:
            return {...state, isLoading: true, isDoneLoading: false, error: false};
        case SEND_PNA_REQUEST:
            let payload = action.payload;
            return {...state, pnaLoading: manageLoading(state.pnaLoading, payload.sku), error: false, xmlErrors: null};
        case SEARCH_FOR_PRODUCTS_SUCCESS:
            return {...state, isDoneLoading: true, isLoading: false, activeProducts: action.payload};
        case GET_PRODUCT_DETAILS_SUCCESS:
            return {...state, productDetails: action.payload, isLoading: false, isDoneLoading: true};
        case SEND_PNA_SUCCESS:
            let result = action.payload;
            let newDetails = {...state.productDetails};
            let newSkus = [];
            let updated = [...state.updated];

            if (result && !result.error) {
                updated.push(result.sku);
                let newSku = newDetails.skus.filter(s => s.sku === result.sku)[0];
                let avResult = result.availability;
                if (avResult) {
                    let updatedPrice = parseFloat(result.price.replace('$', '').replace(",", "")).toFixed(2);
                    let updatedQuantity = 0;
                    let availability = avResult.map(a => {
                        if (!a.quantity.toString().match(/\b9+\b/)) {
                            updatedQuantity += parseInt(a.quantity);
                        }
                        return {
                            warehouseId: a.id,
                            warehouseName: a.name,
                            quantity: a.quantity
                        }
                    });
                    let sku = {
                        ...newSku,
                        rate: updatedPrice,
                        totalQuantity: updatedQuantity,
                        warehouses: [...availability]
                    };
                    newSkus.push(sku);
                }
            }

            let updatedVendors = newSkus.map(s => s.sku.trim());
            newDetails.skus = [...newDetails.skus.filter(s => updatedVendors.indexOf(s.sku.trim()) === -1), ...newSkus];
            return {
                ...state,
                productDetails: newDetails,
                pnaLoading: manageLoading(state.pnaLoading, result.sku),
                updated: updated,
                error: false
            };
        case SEARCH_FOR_PRODUCTS_ERROR:
        case GET_PRODUCT_DETAILS_ERROR:
        case SEND_PNA_ERROR:
            return {
                ...state,
                pnaLoading: manageLoading(state.pnaLoading, action.payload.sku),
                isDoneLoading: true,
                error: action.payload.message
            };
        default:
            return state
    }
}