import {getPAndARequest, getProductDetailsRequest, searchForProductsRequest} from "../api/productApi";

export const SEARCH_FOR_PRODUCTS_REQUEST = "SEARCH_FOR_PRODUCT_REQUEST";
export const SEARCH_FOR_PRODUCTS_SUCCESS = "SEARCH_FOR_PRODUCT_SUCCESS";
export const SEARCH_FOR_PRODUCTS_ERROR = "SEARCH_FOR_PRODUCT_ERROR";
export const GET_PRODUCT_DETAILS_REQUEST = "GET_PRODUCT_DETAILS_REQUEST";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_ERROR = "GET_PRODUCT_DETAILS_ERROR";
export const SEND_PNA_REQUEST = "SEND_PNA_REQUEST";
export const SEND_PNA_SUCCESS = "SEND_PNA_SUCCESS";
export const SEND_PNA_ERROR = "SEND_PNA_ERROR";

/**
 * Action that searches for a product in the Catalog
 * @param searchField Field on which to base the search
 * @param term term to search for
 * @returns {Function}
 */
export const searchForProducts = (searchField, term) => async dispatch => {
    try {
        dispatch({type: SEARCH_FOR_PRODUCTS_REQUEST});

        let products = [];
        if (searchField != null && term != null) {
            let params = constructUrlParams(searchField, term);
            products = await searchForProductsRequest(params);
        }

        dispatch({
            type: SEARCH_FOR_PRODUCTS_SUCCESS,
            payload: products
        })
    } catch (e) {
        dispatch({type: SEARCH_FOR_PRODUCTS_ERROR, payload: e.message});
    }
};

/**
 * Action that requests the details of a product based on an Id
 * @param prodId Id of the product
 * @returns {Function}
 */
export const getProductDetails = (prodId) => async dispatch => {
    try {
        dispatch({type: GET_PRODUCT_DETAILS_REQUEST});

        let details = await getProductDetailsRequest(prodId);

        dispatch({
            type: GET_PRODUCT_DETAILS_SUCCESS,
            payload: details
        })
    } catch (e) {
        dispatch({type: GET_PRODUCT_DETAILS_ERROR, payload: e.message});
    }
};

/**
 * Action that sends an XML PNA request for each vendor of the current product
 * @returns {Function}
 */
export const getPNAForProduct = () => async (dispatch, getState) => {
    const product = getState().products.productDetails;
    const skuList = product.skus.map(s => {
        return {sku: s.sku, vendor: s.vendor}
    });

    for (let i = 0; i < skuList.length; i++) {
        try {
            dispatch({
                type: SEND_PNA_REQUEST,
                payload: skuList[i]
            });

            getPAndARequest(skuList[i]).then(data => dispatch({
                type: SEND_PNA_SUCCESS,
                payload: data
            }));

        } catch (e) {
            dispatch({
                type: SEND_PNA_ERROR,
                payload: {
                    message: e.message,
                    sku: skuList[i].sku
                }
            });
        }
    }
};

/**
 * Construct the url parameters for a product search
 * @param searchField field on which to base search
 * @param term Term to search for
 * @returns {URLSearchParams}
 */
let constructUrlParams = (searchField, term) => {
    const params = new URLSearchParams();
    term = term.trim();

    if (searchField === "chk-oem") params.append('oem', term);
    if (searchField === "chk-descr") params.append('descr', term);
    if (searchField === "chk-sku") params.append('sku', term);
    return params;
};

