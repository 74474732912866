import {
    ADD_BID_ERROR,
    ADD_BID_REQUEST,
    ADD_BID_SUCCESS,
    DELETE_BID_ERROR,
    DELETE_BID_REQUEST,
    DELETE_BID_SUCCESS,
    GET_BIDS_ERROR,
    GET_BIDS_REQUEST,
    GET_BIDS_SUCCESS
} from "../actions/bidActions";

const defaultState = {
    bids: [],
    error: false,
    isLoading: false,
    wasAdded: false
};

export default (state = {...defaultState}, action) => {
    switch (action.type) {
        case GET_BIDS_REQUEST:
        case DELETE_BID_REQUEST:
            return {...state, error: false, isLoading: true, wasAdded: false};
        case ADD_BID_REQUEST:
            return {...state, error: false, isLoading: true};
        case ADD_BID_SUCCESS:
            return {...state, error: false, isLoading: false, wasAdded: true};
        case GET_BIDS_SUCCESS:
            return {...state, error: false, isLoading: false, bids: action.payload};
        case ADD_BID_ERROR:
            return {...state, error: action.payload, isLoading: false, wasAdded: false};
        case DELETE_BID_SUCCESS:
            return {...state, error: false, isLoading: false};
        case GET_BIDS_ERROR:
        case DELETE_BID_ERROR:
            return {...state, isLoading: false, error: action.payload};
        default:
            return state
    }
}