import {SAVE_SEARCH_ERROR, SAVE_SEARCH_REQUEST, SAVE_SEARCH_SUCCESS} from "../actions/productsListActions";

import {SEARCH_FOR_PRODUCTS_SUCCESS} from "../actions/productActions";

const defaultState = {
    field: null,
    activeHeader: null,
    isLoading: false,
    error: false,
    lastSearch: "",
    checkChoice: "chk-oem"
};

export default (state = {...defaultState}, action) => {
    switch (action.type) {
        case SEARCH_FOR_PRODUCTS_SUCCESS:
            return {...state, activeHeader: action.payload, isLoading: false, error: false};
        case SAVE_SEARCH_REQUEST:
            return {...state, isLoading: true, error: false};
        case SAVE_SEARCH_SUCCESS:
            return {
                ...state,
                lastSearch: action.payload.searchTerm,
                checkChoice: action.payload.checkChoice,
                isLoading: false,
                error: false
            };
        case SAVE_SEARCH_ERROR:
            return {...state, error: action.payload, isLoading: false};
        default:
            return state
    }
}