import {
    baseUrl,
    DH_PROVIDER_NAME,
    INGRAM_PROVIDER_NAME,
    SYNNEX_PROVIDER_NAME,
    TECH_PROVIDER_NAME,
    XML_DH_URL,
    XML_INGRAM_URL,
    XML_SYNNEX_URL,
    XML_TECH_URL
} from "../utils/constants";

import {getToken} from "./authApi";

import axios from "axios";

/**
 * Sends a request to the API to search for products
 * @param params Parameters to use in the search
 * @returns {Object}
 */
export const searchForProductsRequest = async (params) => {
    let token = await getToken();
    let url = baseUrl + '/productSearch';
    const res = await axios.get(url, {
        params: params,
        headers: {
            "Authorization": token
        }
    });
    return res.data;
};

/**
 * Sends a request to the API to get the details of a product
 * @param prodId Id of the product to get
 * @returns {Object}
 */
export const getProductDetailsRequest = async (prodId) => {
    let token = await getToken();
    let url = baseUrl + '/products/' + prodId;
    const res = await axios.get(url, {
        headers: {
            "Authorization": token
        }
    });
    return res.data;
};

/**
 * Sends a request to the API to get a PNA update for Tech Data and parses the response
 * @param sku Sku to update
 * @returns {Promise<{vendor: *, error: string, sku: *}|{vendor: *, price: *, availability: *, sku: *}|{vendor: *, error: *, sku: *}|undefined>}
 */
const getPAndAReqTech = async (sku) => {
    let domParser = new DOMParser();
    const res = await sendTechDataRequest(sku);

    if (res) {
        return parseTechDataResponseXML(domParser.parseFromString(res.data, "application/xml"), sku);
    } else {
        return {
            vendor: TECH_PROVIDER_NAME,
            error: "timeout error",
            sku: sku
        }
    }
};

/**
 * Sends a request to the API to get a PNA update for Ingram Micro and parses the response
 * @param sku Sku to update
 * @returns {Promise<{vendor: *, error: string, sku: *}|{vendor: *, price: *, availability: *, sku: *}|{vendor: *, error: *, sku: *}|undefined>}
 */
const getPAndAReqIngram = async (sku) => {
    let domParser = new DOMParser();
    const res = await sendIngramRequest(sku);

    if (res) {
        return parseIngramResponseXML(domParser.parseFromString(res.data, "application/xml"), sku);
    } else {
        return {
            vendor: INGRAM_PROVIDER_NAME,
            error: "timeout error",
            sku: sku
        }
    }
};

/**
 * Sends a request to the API to get a PNA update for Synnex and parses the response
 * @param sku Sku to update
 * @returns {Promise<{vendor: *, error: string, sku: *}|{vendor: *, price: *, availability: *, sku: *}|{vendor: *, error: *, sku: *}|undefined>}
 */
const getPAndAReqSynnex = async (sku) => {
    let domParser = new DOMParser();
    const res = await sendSynnexRequest(sku);

    if (res) {
        return parseSynnexResponseXML(domParser.parseFromString(res.data, "application/xml"), sku);
    } else {
        return {
            vendor: SYNNEX_PROVIDER_NAME,
            error: "timeout error",
            sku: sku
        }
    }
};

/**
 * Sends a request to the API to get a PNA update for D&H and parses the response
 * @param sku Sku to update
 * @returns {Promise<{vendor: *, error: string, sku: *}|{vendor: *, price: *, availability: *, sku: *}|{vendor: *, error: *, sku: *}|undefined>}
 */
const getPAndAReqDH = async (sku) => {
    let domParser = new DOMParser();
    const res = await sendDHRequest(sku);

    if (res) {
        return parseDHResponseXML(domParser.parseFromString(res.data, "application/xml"), sku);
    } else {
        return {
            vendor: DH_PROVIDER_NAME,
            error: "timeout error",
            sku: sku
        }
    }
};

/**
 * Sends a request to the API to get a PNA update for a vendor
 * @param sku Sku to update
 * @returns {Promise<{vendor: *, error: string, sku: *}|{vendor: *, price: *, availability: *, sku: *}|{vendor: *, error: *, sku: *}|undefined>}
 */
export const getPAndARequest = async (sku) => {
    if (sku.vendor === INGRAM_PROVIDER_NAME) {
        return getPAndAReqIngram(sku.sku);
    } else if (sku.vendor === SYNNEX_PROVIDER_NAME) {
        return getPAndAReqSynnex(sku.sku);
    } else if (sku.vendor === TECH_PROVIDER_NAME) {
        return getPAndAReqTech(sku.sku);
    } else if (sku.vendor === DH_PROVIDER_NAME) {
        return getPAndAReqDH(sku.sku);
    }
};

/**
 * Sends a PNA request for a Ingram Sku
 * @param sku Sku to update
 * @returns {Promise<AxiosResponse<T>|void>}
 */
const sendIngramRequest = async (sku) => {
    let token = await getToken();

    return await axios.get(XML_INGRAM_URL + sku, {
        timeout: 10000,
        headers: {
            "Authorization": token
        }
    }).catch((e) => console.log(e.message + " for " + INGRAM_PROVIDER_NAME));
};

/**
 * Sends a PNA request for a Synnex Sku
 * @param sku Sku to update
 * @returns {Promise<AxiosResponse<T>|void>}
 */
const sendSynnexRequest = async (sku) => {
    let token = await getToken();

    return await axios.get(XML_SYNNEX_URL + sku, {
        timeout: 10000,
        headers: {
            "Authorization": token
        }
    }).catch((e) => console.log(e.message + " for " + SYNNEX_PROVIDER_NAME));
};

/**
 * Sends a PNA request for a Tech Data Sku
 * @param sku Sku to update
 * @returns {Promise<AxiosResponse<T>|void>}
 */
const sendTechDataRequest = async (sku) => {
    let token = await getToken();

    return await axios.get(XML_TECH_URL + sku, {
        timeout: 10000,
        headers: {
            "Authorization": token
        }
    }).catch((e) => console.log(e.message + " for " + TECH_PROVIDER_NAME));
};

/**
 * Sends a PNA request for a D&H Sku
 * @param sku Sku to update
 * @returns {Promise<AxiosResponse<T>|void>}
 */
const sendDHRequest = async (sku) => {
    let token = await getToken();

    return await axios.get(XML_DH_URL + sku, {
        timeout: 10000,
        headers: {
            "Authorization": token
        }
    }).catch((e) => console.log(e.message + " for " + DH_PROVIDER_NAME));
};

/**
 * Parses an XML PNA response from Ingram Micro
 * @param data XML Data
 * @param sku Sku updated
 * @returns {{vendor: *, price: *, availability: *, sku: *}|{vendor: *, error: *, sku: *}}
 */
const parseIngramResponseXML = (data, sku) => {
    if (data.documentElement.nodeName !== "parsererror") {
        let ingramWarehouses = data.getElementsByTagName('Branch');
        let error = data.getElementsByTagName('SKUStatus')[0] ? data.getElementsByTagName('SKUStatus')[0].textContent : null;
        let ingramAvailability = [];
        for (let i = 0; i < ingramWarehouses.length; i++) {
            const ingramKey = ingramWarehouses[i].getAttribute("Name");
            ingramAvailability = [...ingramAvailability, {
                name: ingramKey,
                quantity: ingramWarehouses[i].getElementsByTagName("Availability")[0].textContent,
                id: ingramWarehouses[i].getAttribute("ID")
            }];
        }

        if (ingramAvailability.length > 0) {
            return {
                vendor: INGRAM_PROVIDER_NAME,
                sku: data.getElementsByTagName('PriceAndAvailability')[0].getAttribute('SKU'),
                price: data.getElementsByTagName("Price")[0].textContent,
                availability: ingramAvailability
            }
        } else if (error) {
            return {
                vendor: INGRAM_PROVIDER_NAME,
                error: error,
                sku: sku
            }
        }
    } else {
        return {
            vendor: INGRAM_PROVIDER_NAME,
            error: "error while parsing",
            sku: sku
        }
    }
};

/**
 * Parses an XML PNA response from Synnex
 * @param data XML Data
 * @param sku Sku updated
 * @returns {{vendor: *, price: *, availability: *, sku: *}|{vendor: *, error: *, sku: *}}
 */
const parseSynnexResponseXML = (data, sku) => {
    if (data.documentElement.nodeName !== "parsererror") {
        let synnexWarehouses = data.getElementsByTagName('AvailabilityByWarehouse');
        let error = data.getElementsByTagName('errorDetail')[0] ? data.getElementsByTagName('errorDetail')[0].textContent : null;
        let synnexAvailability = [];
        for (let i = 0; i < synnexWarehouses.length; i++) {
            const synnexKey = synnexWarehouses[i].getElementsByTagName("city")[0].textContent;
            synnexAvailability = [...synnexAvailability, {
                name: synnexKey,
                quantity: synnexWarehouses[i].getElementsByTagName("qty")[0].textContent,
                id: synnexWarehouses[i].getElementsByTagName("number")[0].textContent
            }];
        }

        if (synnexAvailability.length > 0) {
            return {
                vendor: SYNNEX_PROVIDER_NAME,
                sku: data.getElementsByTagName('synnexSKU')[0].textContent,
                price: data.getElementsByTagName("price")[0].textContent,
                availability: synnexAvailability
            }
        } else if (error) {
            return {
                vendor: SYNNEX_PROVIDER_NAME,
                error: error,
                sku: sku
            }
        }
    } else {
        return {
            vendor: SYNNEX_PROVIDER_NAME,
            error: "error while parsing",
            sku: sku
        }
    }
};

/**
 * Parses an XML PNA response from Tech Data
 * @param data XML Data
 * @param sku Sku updated
 * @returns {{vendor: *, price: *, availability: *, sku: *}|{vendor: *, error: *, sku: *}}
 */
const parseTechDataResponseXML = (data, sku) => {
    if (data.documentElement.nodeName !== "parsererror") {
        let techWarehouses = data.getElementsByTagName('WhseInfo');
        let error = data.getElementsByTagName('ErrorDesc')[0] ? data.getElementsByTagName('ErrorDesc')[0].textContent : null;
        let techAvailability = [];
        for (let i = 0; i < techWarehouses.length; i++) {
            const techKey = techWarehouses[i].getElementsByTagName("IDCode")[0].textContent;
            techAvailability = [...techAvailability, {
                name: techKey,
                quantity: techWarehouses[i].getElementsByTagName("Qty")[0].textContent,
                id: techWarehouses[i].getElementsByTagName("WhseCode")[0].textContent
            }];
        }

        if (techAvailability.length > 0) {
            return {
                vendor: TECH_PROVIDER_NAME,
                sku: data.getElementsByTagName('RefID1')[0].textContent,
                price: data.getElementsByTagName("UnitPrice1")[0].textContent,
                availability: techAvailability
            }
        } else if (error) {
            return {
                vendor: TECH_PROVIDER_NAME,
                error: error,
                sku: sku
            }
        }
    } else {
        return {
            vendor: TECH_PROVIDER_NAME,
            error: "error while parsing",
            sku: sku
        }
    }
};

/**
 * Parses an XML PNA response from D&H
 * @param data XML Data
 * @param sku Sku updated
 * @returns {{vendor: *, price: *, availability: *, sku: *}|{vendor: *, error: *, sku: *}}
 */
const parseDHResponseXML = (data, sku) => {
    if (data.documentElement.nodeName !== "parsererror") {
        let dhWarehouses = data.getElementsByTagName('BRANCHQTY');
        let error = data.getElementsByTagName('STATUS')[0] ? data.getElementsByTagName('STATUS')[0].textContent === 'failure' : null;
        let dhAvailability = [];
        for (let i = 0; i < dhWarehouses.length; i++) {
            dhAvailability = [...dhAvailability, {
                name: dhWarehouses[i].getElementsByTagName("BRANCH")[0].textContent,
                quantity: dhWarehouses[i].getElementsByTagName("QTY")[0].textContent,
                id: "DH - " + i
            }];
        }

        if (dhAvailability.length > 0) {
            return {
                vendor: DH_PROVIDER_NAME,
                sku: data.getElementsByTagName('PARTNUM')[0].textContent,
                price: data.getElementsByTagName("UNITPRICE")[0].textContent,
                availability: dhAvailability
            }
        } else if (error) {
            return {
                vendor: DH_PROVIDER_NAME,
                error: error,
                sku: sku
            }
        }
    } else {
        return {
            vendor: DH_PROVIDER_NAME,
            error: "error while parsing",
            sku: sku
        }
    }
};