import React from 'react';
import {connect} from "react-redux";
import {addBid, deleteBid, getBids} from "../actions/bidActions";
import {withRouter} from "react-router";
import Table from "./Table";

/**
 * React component that manages the manufacturer correspondences
 */
class BIDManager extends React.Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            isLoading: false,
            wasSuccess: null,
            error: false,

        };
        this.handleUpload = this.handleUpload.bind(this);
        this.renderExistingBids = this.renderExistingBids.bind(this);
        this.deleteBid = this.deleteBid.bind(this);
    }

    /**
     * Event handler for when the component is ready
     */
    componentDidMount() {
        this.props.getBids()
    }

    /**
     * Handler for adding a bid
     */
    handleUpload() {
        const data = new FormData();
        data.append('file', this.state.file);
        this.props.addBid(data)
    };

    /**
     * Sort function using a Bid version
     * @param a Bid a
     * @param b Bid b
     * @returns {number}
     */
    sortByVersion(a, b) {
        return parseInt(b.revision) - parseInt(a.revision);
    }

    /**
     * Handler for deleting a Bid
     * @param id_bid Id of the Bid to delete
     */
    deleteBid(id_bid) {
        this.props.deleteBid(id_bid);
    }

    /**
     * Sort function using a product oem
     * @param a Product a
     * @param b product b
     * @returns {number}
     */
    sortByOem(a, b) {
        return (a.oem.toLowerCase() < b.oem.toLowerCase()) ? -1 : (a.oem.toLowerCase() > b.oem.toLowerCase()) ? 1 : 0;
    }

    /**
     * Function that returns the products and margins of the selected Bid
     * @returns {{margins: *, products: *}}
     */
    getProductsAndMargins() {
        const {allBids} = this.props;
        const {selectedBid} = this.state;
        let margins = [];
        let products = [];

        if (selectedBid) {
            let filter = allBids.filter(b => b.id === selectedBid)[0];

            if (filter) {
                margins = filter.margins;
                products = filter.products;
            }
        }

        return {
            margins,
            products
        }
    }

    /**
     * Render the Bids table
     * @param allBids all existing bids
     * @param selectedBid the selected bid
     * @returns {*}
     */
    renderBidsTable(allBids, selectedBid) {
        let tableHeaders = ["#", "Nom", "Nombre", "Révision", "Expiration", ""];
        return <Table headers={tableHeaders} isClickable={true} centeredIndexes={[0, 2, 3, 4]}>
            {allBids.length > 0 ? allBids.sort(this.sortByVersion).map((b, i) => <tr
                className={selectedBid === b.id ? "selected" : ""} key={b.id} onClick={(e) => {
                e.stopPropagation();
                this.setState({selectedBid: b.id})
            }}>
                <td>{i + 1}</td>
                <td>{b.name}</td>
                <td className={"center"}>{b.number}</td>
                <td className={"center"}>{b.revision}</td>
                <td className={"center"}>{b.expiration}</td>
                <td id={"delete-icon"}><img onClick={() => this.deleteBid(b.id)}
                                            src="https://img.icons8.com/flat_round/64/000000/delete-sign.png"
                                            alt={"delete icon"} title={"Supprimer le BID"}/></td>
            </tr>) : <tr>
                <td colSpan={6}>Aucun BID à afficher</td>
            </tr>}
        </Table>
    }

    /**
     * Render the Bid products table
     * @param products Products to render
     * @returns {*}
     */
    renderBidProductsTable(products) {
        let tableHeaders = [
            "#", "OEM", "Description", "Groupe", "Prix affiché",
            "Remboursement", "Coût ML", "Qté", "Date début", "Date fin"
        ];

        return <Table id={"products-table"} headers={tableHeaders} isClickable={false} centeredIndexes={[0, 3, 4, 5]}>
            {products.length > 0 ? products.sort(this.sortByOem).map((p, i) => <tr key={p.id}>
                <td>{i + 1}</td>
                <td>{p.oem}</td>
                <td>{p.description}</td>
                <td className={"center"}>{p.group}</td>
                <td className={"center"}>{p.listPrice}</td>
                <td className={"center"}>{p.refund}</td>
                <td>{p.costMl}</td>
                <td>{p.remainingQty}</td>
                <td>{p.startDate}</td>
                <td>{p.endDate}</td>
            </tr>) : <tr>
                <td colSpan={10}>Veuillez sélectionner un BID</td>
            </tr>}
        </Table>
    }

    /**
     * Render the Bid margins table
     * @param margins margins to display
     * @returns {*}
     */
    renderBidMarginsTable(margins) {
        let tableHeaders = ["#", "Nom Fourn.", "Groupe", "Marge"];

        return <Table id={"margins-table"} headers={tableHeaders} isClickable={false} centeredIndexes={[0, 2, 3]}>
            {margins.length > 0 ? margins.map((m, i) => <tr key={m.id}>
                <td>{i + 1}</td>
                <td>{m.vendorName}</td>
                <td className={"center"}>{m.group}</td>
                <td className={"center"}>{m.margin * 100} %</td>
            </tr>) : <tr>
                <td colSpan={4}>Veuillez sélectionner un BID</td>
            </tr>}
        </Table>
    }

    /**
     * Function to render the Bid tables
     * @returns {*}
     */
    renderExistingBids() {
        const {allBids} = this.props;
        const {selectedBid} = this.state;
        const {margins, products} = this.getProductsAndMargins();


        return <div className={"flex bid"}>
            <div>
                <h3>BIDs existants</h3>
                {this.renderBidsTable(allBids, selectedBid)}
            </div>
            <div>
                <h3>Marges du BID sélectionné</h3>
                {this.renderBidMarginsTable(margins)}
            </div>
            <div>
                <h3>Produits du BID sélectionné</h3>
                {this.renderBidProductsTable(products)}
            </div>
        </div>
    }

    /**
     * Render function
     * @returns {*}
     */
    render() {
        const {error, wasSuccess} = this.props;
        return <div className={'borne'} onClick={() => this.setState({selectedBid: null})}>
            <h2>Gestion des BIDs</h2>
            <div className={'bid-add'}>
                <h3>+ Ajouter un BID</h3>
                <input type={'file'} accept=".xlsx" onChange={(e) => this.setState({file: e.target.files[0]})}/>
                {this.state.file ? <button onClick={this.handleUpload}>Ajouter BID</button> : null}
                {wasSuccess ? <p>BID ajouté avec succès</p> : error ? <p>Une erreur est survenue</p> : null}
            </div>
            <div>
                <button onClick={() => this.props.history.goBack()}>{'< Page précédente'}</button>
            </div>
            {this.renderExistingBids()}
        </div>
    }
}

const mapDispatchToProps = dispatch => ({
    getBids: () => dispatch(getBids()),
    addBid: (file) => dispatch(addBid(file)),
    deleteBid: (bid_id) => dispatch(deleteBid(bid_id))
});

const mapStateToProps = (state) => {
    return {
        allBids: state.bids.bids || [],
        isLoading: state.bids.isLoading,
        error: state.bids.error,
        wasSuccess: state.bids.wasSuccess
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BIDManager));