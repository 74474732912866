import React from 'react';
import PropTypes from 'prop-types';

/**
 * Loading wheel react component
 */
class Table extends React.Component {

    /**
     * Constructor function
     * @param props
     */
    constructor(props) {
        super(props);
        this.getClassNameCentered = this.getClassNameCentered.bind(this);
    }

    /**
     * Returns the correct classname if the index is supposed to be centered
     * @param currentIndex index of the current column
     * @returns {string}
     */
    getClassNameCentered(currentIndex) {
        const {centeredIndexes} = this.props;
        return centeredIndexes.includes(currentIndex) || centeredIndexes.includes(currentIndex.toString()) ? "center" : ""
    }

    /**
     * Render function
     * @returns {*}
     */
    render() {
        const {headers, isClickable, id, className, containerClass} = this.props;

        return <div className={`table-box ${containerClass}`}>
            <table cellPadding="5px" className={`${className} ${isClickable ? "" : "no-hover"}`}>
                <thead>
                    <tr>
                        {headers.map((header, i) => <th key={header + i} id={id} className={this.getClassNameCentered(i)} >{header}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {this.props.children}
                </tbody>
            </table>
        </div>
    }
}

Table.defaultProps = {
    id: '',
    className: '',
    containerClass: ''
};

Table.propsType = {
    headers: PropTypes.array.isRequired,
    isClickable: PropTypes.bool.isRequired,
    centeredIndexes: PropTypes.array.isRequired
};

export default Table;