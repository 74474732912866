import {API_PASS, API_UNAME, baseUrl, SECRET_KEY} from "../utils/constants";
import axios from "axios";

let jwt = require('jsonwebtoken');

/**
 * Validates that the current token is valid
 * @returns {boolean} True if the token is valid, false otherwise
 */
const validateToken = () => {
    let token = localStorage.getItem('access_token');
    if (token) {
        try {
            jwt.verify(token, SECRET_KEY);
            return true;
        } catch (err) {
            return false
        }
    }
    return false
};

/**
 * Gets a valid access token, refreshes the token if necessary
 * @returns {Promise<string>}
 */
export const getToken = async () => {
    if (!validateToken()) {
        let url = baseUrl + '/auth';
        const res = await axios.post(url, {
            "username": API_UNAME,
            "password": API_PASS
        });
        if (res.data.auth_token) {
            localStorage.setItem("access_token", res.data.auth_token);
        }
    }
    return localStorage.getItem('access_token');
};
