import React from 'react'
import {Route} from 'react-router'
import ProductsDetails from './ProductsDetails'
import ProductsList from './ProductsList'
import Landing from './Landing'
import ManufacturersManager from './ManufacturersManager'
import BIDManager from './BIDManager'

/**
 * React component that manages navigation
 */
export class Routes extends React.Component {

    /**
     * Main render function
     * @returns {*}
     */
    render() {
        if (this.props.isAuthenticated) {
            return <div>
                <Route exact path="/products" render={() => <ProductsList {...this.props}/>}/>
                <Route exact path="/products/:id" render={() => <ProductsDetails {...this.props}/>}/>
                <Route exact path="/manufacturers-manager" render={() => <ManufacturersManager {...this.props}/>}/>
                <Route exact path="/bid" render={() => <BIDManager {...this.props}/>}/>
                <Route exact path="/" render={() => <Landing {...this.props}/>}/>
            </div>
        }
        return <div>
            <h2>Bienvenue sur le Catalogue !</h2>
            <p>Pour avoir accès à cet outil, vous devez vous connecter.</p>
        </div>
    }
}