import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {deleteManufacturerCorr, getEmptyManufacturers, updateEmptyManufacturers} from '../actions/manufacturerActions';
import {withRouter} from "react-router";
import StringSimilarity from 'js-levenshtein';
import Table from "./Table";
import {getExcelExport} from "../api/manufacturersApi";


/**
 * React component that manages the manufacturers
 */
class ManufacturersManager extends React.Component {

    /**
     * Constructor function
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            confirmedCorrespondences: [],
            indexMin: 0,
            unconfirmedCorrespondences: {},
            filter: "new",
        };

        this.renderTable = this.renderTable.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.renderTableLines = this.renderTableLines.bind(this);
        this.toggleManufacturer = this.toggleManufacturer.bind(this);
        this.renderTableNavigation = this.renderTableNavigation.bind(this);
        this.filterExpired = this.filterExpired.bind(this);
        this.filterAll = this.filterAll.bind(this);
        this.filterNew = this.filterNew.bind(this);
        this.renderChoicesLines = this.renderChoicesLines.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    /**
     * Event handler that get the manufacturer aliases when the component is ready
     */
    componentDidMount() {
        this.props.getEmptyManufacturers();
    }

    /**
     * Event handler that manages props and state after the component receives new props
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {allCorrespondences, allManufacturers} = this.props;
        let newState = {};
        let assignedCorrespondences = allCorrespondences.filter(m => m.man_code != null);

        let filterRows = () => {
            if (this.state.filter === 'all') {
                    return assignedCorrespondences.filter(m => m.is_expired === false && m.is_new === false);
            } else if (this.state.filter === 'new') {
                return allCorrespondences.filter(m => m.is_new === true && m.is_expired === false);
            } else {
                return allCorrespondences.filter(m => m.is_expired === true && m.is_new === false && !m.vendors);
            }
        };

        if (prevProps.allCorrespondences !== allCorrespondences) {
            let unconfirmedCorrespondences = {};

            assignedCorrespondences.forEach(correspondence => {
                let manuf = allManufacturers.find(man => man.id === correspondence.man_id);
                unconfirmedCorrespondences[correspondence.alias] =
                    {
                        "man_code": manuf.code,
                        "valid": true
                    }
            });
            newState = {
                ...newState,
                unconfirmedCorrespondences: unconfirmedCorrespondences,
                filteredCorrespondences: filterRows()
            };

        }

        if (prevState.filter !== this.state.filter) {
            newState = {...newState, filteredCorrespondences: filterRows()}
        }

        if ("unconfirmedCorrespondences" in newState || "filteredCorrespondences" in newState) {
            this.setState(newState);
        }
    }

    /**
     * Changes the active alias state
     * @param alias New selected alias
     */
    toggleManufacturer(alias) {
        this.setState({activeAlias: alias, specificAlias: null});
    }

    /**
     * Handler that adds a new alias-manufacturer pairing on
     * @param manCode the alias to pair
     * @param alias the manufacturer code to link the alias to
     */
    handleChange(manCode, alias) {
        const {allManufacturers, allCorrespondences} = this.props;
        let {confirmedCorrespondences} = this.state;

        let groupObj = allManufacturers.find(man => man.code === manCode);
        let aliasObj = allCorrespondences.find(man => man.alias === alias);

        let modifs = [...confirmedCorrespondences];

        if (aliasObj && confirmedCorrespondences.findIndex(m => m.man_corr_id === aliasObj.man_id) > -1) {
            let index = confirmedCorrespondences.findIndex(m => m.man_corr_id === aliasObj.man_id);
            modifs.splice(index, 1);
        }

        if ((groupObj || !isNaN(alias)) && aliasObj) {
            modifs.push({
                "man_corr_id": aliasObj.id,
                "man_id": groupObj.id
            });
        }
        this.setState({confirmedCorrespondences: modifs});
    }

    /**
     * handler that adds a link between an alias and a string
     * @param e Event
     * @param alias Alias selected
     */
    handleTextChange(e, alias) {
        this.handleChange(e.target.value.toUpperCase(), alias);
        this.setState({
            activeAlias: alias,
            unconfirmedCorrespondences: {...this.state.unconfirmedCorrespondences, [alias]: {
                    "man_code": e.target.value.toUpperCase()
                }},
            specificAlias: e.target.value.toUpperCase()
        });
    }

    /**
     * handler that handles an alias select change
     * @param manId new manufacturer id
     */
    handleChoiceChange(manId) {
        const {activeAlias} = this.state;
        if (activeAlias) {
            this.handleChange(manId, activeAlias);
            this.setState({unconfirmedCorrespondences: {...this.state.unconfirmedCorrespondences, [activeAlias]: {
                    "man_code": manId
                }},
            })
        }
    }

    /**
     * Sort function that uses an alias
     * @param a manufacturer a
     * @param b manufacturer b
     * @returns {number}
     */
    sortByAlias = (a, b) => {
        return (a.alias && a.alias.toLowerCase() < b.alias.toLowerCase()) ? -1 : (a.alias && a.alias.toLowerCase() > b.alias.toLowerCase()) ? 1 : 0;
    };

    /**
     * Function that renders the main table view
     * @returns {*[]}
     */
    renderTableLines() {
        const {indexMin, filteredCorrespondences, activeAlias, unconfirmedCorrespondences, confirmedCorrespondences, filter} = this.state;
        return filteredCorrespondences.length > 0 ? filteredCorrespondences.sort(this.sortByAlias).slice(indexMin, indexMin + 100).map((g, i) => {
            let isModif = confirmedCorrespondences.findIndex(m => (m.man_corr_id === g.man_id) || (m.man_corr_id === g.id)) > -1;
            let isValidCorrespondence = unconfirmedCorrespondences[g.alias] != null && unconfirmedCorrespondences[g.alias].valid;
            let isValidCorr = isModif || isValidCorrespondence || !unconfirmedCorrespondences[g.alias] || unconfirmedCorrespondences[g.alias].code === "";

            return <Fragment key={'f-' + i}>
                <tr className={`${activeAlias && activeAlias === g.alias ? "selected" : ""} ${!isValidCorr ? "invalid" : ""}`}
                    key={g.id} id={g.id} onClick={() => this.toggleManufacturer(g.alias)}>
                    <td>{indexMin + i + 1}</td>
                    <td>{g.alias}</td>
                    <td className={"center"}><input type={'text'} disabled={g.is_expired} value={unconfirmedCorrespondences[g.alias] ? unconfirmedCorrespondences[g.alias].man_code : ""}
                               onChange={(e) => this.handleTextChange(e, g.alias)}/></td>
                    <td className={"center"}>{g.vendors ? g.vendors : '-'}</td>
                    <td className={"center"}>{g.is_new ? "Oui" : "Non"}</td>
                    <td className={"center"}>{g.is_expired ? "Oui" : "Non"}</td>
                    {filter === "expired" ? <td className={"center"} id={"delete-icon"}><img onClick={() => this.props.deleteCorrespondence(g.id)}
                                                src="https://img.icons8.com/flat_round/64/000000/delete-sign.png"
                                                alt={"delete icon"} title={"Supprimer la correspondance"}/></td>: null}
                </tr>
            </Fragment>
        }) : <tr>
            <td colSpan={7}>Aucun manufacturier non classé</td>
        </tr>
    }

    /**
     * Function that renders the manufacturers table view
     * @returns {*}
     */
    renderChoicesLines() {
        const {allManufacturers} = this.props;
        let {activeAlias, unconfirmedCorrespondences, specificAlias} = this.state;
        let filteredCorrespondences = allManufacturers;
        if (activeAlias) {
            let alias = specificAlias ? specificAlias : activeAlias;
            for (let i = 0; i < filteredCorrespondences.length; i++) {
                let words = alias.split(/[ ,.]| - /);
                let lowestIndex = 100;
                for (let j = 0; j < words.length; j++) {
                    let firstWord = allManufacturers[i].name.split(" ")[0];
                    let fraction = StringSimilarity(firstWord.toLowerCase(), words[j].toLowerCase());
                    if (fraction < lowestIndex) {
                        lowestIndex = fraction;
                    }
                }
                allManufacturers[i].fraction = lowestIndex;
            }
        }
        let first10 = filteredCorrespondences.sort((a, b) => a.fraction - b.fraction).slice(0, 10);
        let containsLetter = filteredCorrespondences.sort((a, b) => a.fraction - b.fraction).slice(11, filteredCorrespondences.length).sort((a, b) => a.name.localeCompare(b.name));

        filteredCorrespondences = [...first10, ...containsLetter];
        return filteredCorrespondences.length > 0 ? filteredCorrespondences.map((m, i) => (
            <tr className={unconfirmedCorrespondences[activeAlias] && unconfirmedCorrespondences[activeAlias] === m.code ? "selected" : ""}
                onClick={() => this.handleChoiceChange(m.code)} key={"man-" + i}>
                <td>{i + 1}</td>
                <td>{m.code}</td>
                <td>{m.name}</td>
            </tr>
        )) : <tr>
            <td colSpan={2}>Rien à afficher</td>
        </tr>;
    }

    /**
     * Function that renders the previous hundred lines in the main table
     */
    previousHundred = () => {
        const {indexMin} = this.state;
        if (indexMin - 100 >= 0) {
            this.setState({indexMin: indexMin - 100});
        } else {
            this.setState({indexMin: 0});
        }
    };

    /**
     * Function that renders the next hundred lines in the main table
     */
    nextHundred = () => {
        const {indexMin} = this.state;
        this.setState({indexMin: indexMin + 100});
    };

    /**
     * Function that renders the action buttons
     * @returns {*}
     */
    renderTableNavigation() {
        const {indexMin, filteredCorrespondences} = this.state;

        if (filteredCorrespondences.length > 0) {
            return (
                <div className={"manActionButtons"}>
                    <button disabled={indexMin === 0} className={"action"} onClick={this.previousHundred}>{'<'}</button>
                    <p>{(indexMin + 100) / 100}</p>
                    <button disabled={indexMin + 100 > filteredCorrespondences.length} className={"action"}
                            onClick={this.nextHundred}>{'>'}</button>
                </div>
            )
        }
    }

    /**
     * Function that renders the main table
     * @returns {*}
     */
    renderTable() {
        const {indexMin, filteredCorrespondences, filter} = this.state;

        let tableAliasesheaders = ["#", "Alias", "Code Micro Logic", "Vendeurs", "Est Nouveau", "Est Expiré"];
        if (filter === "expired") {
            tableAliasesheaders.push("");
        }

        let tableManufacturerChoicesheaders = ["#", "Code", "Nom"];

        return (
            <Fragment>
                <p>({indexMin + 1} - {indexMin + 100 < filteredCorrespondences.length ? indexMin + 100 : filteredCorrespondences.length} de {filteredCorrespondences.length})</p>
                <Table containerClass={"final"} headers={tableAliasesheaders} isClickable={true} centeredIndexes={[0, 2, 3, 4, 5, 6]}>
                    {this.renderTableLines()}
                </Table>
                <Table containerClass={"choices"} headers={tableManufacturerChoicesheaders} isClickable={true} centeredIndexes={[0]}>
                    {this.renderChoicesLines()}
                </Table>
                {this.renderTableNavigation()}
            </Fragment>
        )
    }

    /**
     * Function that filters the table lines to new aliases only
     */
    filterNew(e) {
        e.stopPropagation();
        return this.setState({
            indexMin: 0,
            filter: "new"
        })
    }

    /**
     * Function that filters the table lines to expired aliases only
     */
    filterExpired(e) {
        e.stopPropagation();
        return this.setState({
            indexMin: 0,
            filter: "expired"
        })
    }

    /**
     * Function that filters the table lines to new aliases and expired aliases
     */
    filterAll(e) {
        e.stopPropagation();
        return this.setState({
            indexMin: 0,
            filter: "all"
        })
    }

    /**
     * Function that gets the entire table as an excel file to download
     * @param filename Nom du fichier téléchargé
     */
    downloadExcelFile = (filename) => {
        getExcelExport(res => {
            console.log(res);
            let downloadUrl = URL.createObjectURL(res);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = downloadUrl;
            a.download = filename + ".xlsx";
            a.click();
        })
    };

    /**
     * Main render function
     * @returns {*}
     */
    render() {
        const {isLoading} = this.props;
        const {confirmedCorrespondences, filteredCorrespondences} = this.state;
        let date = new Date();
        return <div>
            <h2>Manufacturiers</h2>
            <button onClick={() => this.props.history.goBack()}>{'< Page précédente'}</button>
            <button className={"left-margin"} onClick={() => this.downloadExcelFile("CorrespondancesManufacturieres - " + date.getUTCFullYear() + "/" + (date.getUTCMonth() + 1) + "/" + date.getUTCDate())}>Télécharger en fichier Excel</button>
            <div className={"buttonContainer"}>
                <div>
                    <input disabled={confirmedCorrespondences.length > 0} defaultChecked={true} onChange={this.filterNew} type="radio" id="new" name="filter"
                           value="new"/>
                    <label htmlFor="new">Nouveau seulement</label>
                    <input disabled={confirmedCorrespondences.length > 0} onChange={this.filterExpired} type="radio" id="expired" name="filter" value="expired"/>
                    <label htmlFor="expired">Expirés seulement</label>
                    <input disabled={confirmedCorrespondences.length > 0} onChange={this.filterAll} type="radio" id="all" name="filter" value="all"/>
                    <label htmlFor="all">Tout voir</label>
                </div>
                {confirmedCorrespondences.length > 0 ? <button className={"action"} onClick={() => {
                    this.props.updateEmptyManufacturers(this.state.confirmedCorrespondences);
                    this.setState({"confirmedCorrespondences": []});
                }}>{'Enregistrer'}</button> : null}
            </div>
            {!isLoading && filteredCorrespondences ? this.renderTable(): <p>Chargement...</p> }
        </div>
    }
}

const mapDispatchToProps = dispatch => ({
    getEmptyManufacturers: () => dispatch(getEmptyManufacturers()),
    updateEmptyManufacturers: (modifs) => dispatch(updateEmptyManufacturers(modifs)),
    deleteCorrespondence: (id) => dispatch(deleteManufacturerCorr(id))
});

const mapStateToProps = (state) => {
    return {
        allCorrespondences: state.manufacturers.allCorrespondences || [],
        allManufacturers: state.manufacturers.allManufacturers || [],
        isLoading: state.manufacturers.isLoading,
        activeHeader: state.manufacturers.activeHeader
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManufacturersManager));