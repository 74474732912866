import React, {Fragment} from 'react'
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router";
import Highlight from 'react-highlighter';
import {searchForProducts} from "../actions/productActions";
import {saveSearch} from "../actions/productsListActions";
import Table from "./Table";
import qs from "query-string"

/**
 * React component that manages the product search
 */
class ProductsList extends React.Component {

    /**
     * Constructor function
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            activeProducts: [],
            searchTerm: '',
            search: "chk-oem",
            in_stock_only: false
        };
        this.renderResearchSection = this.renderResearchSection.bind(this);
        this.renderProductsTable = this.renderProductsTable.bind(this);
        this.renderProductLine = this.renderProductLine.bind(this);
        this.redirectToDetailsPage = this.redirectToDetailsPage.bind(this);
        this.searchProduct = this.searchProduct.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);   
    }

    /**
     * Function that launches the product search
     */
    searchProduct = () => {
        const {search, searchTerm} = this.state;
        this.props.searchForProducts(search, searchTerm);
        this.props.saveSearch(searchTerm, search);
    };

    /**
     * Event handler that launches the product search on enter
     * @param e
     */
    listenToEnter = (e) => {
        const {searchTerm, search} = this.state;
        let key = e.which || e.keyCode;
        if (key === 13 && (searchTerm.length > 0 || (search === "chk-oem" && searchTerm.length > 3))) {
            this.searchProduct()
        }
    };

    /**
     * Event handler that initialises event handlers on component ready
     */
    componentDidMount() {
        const {lastSearch, checkChoice} = this.props;
        window.addEventListener('keypress', this.listenToEnter, false);
        document.getElementById('searchInput').focus();
        let addedState = {};
        let oem = decodeURIComponent(qs.parse(this.props.location.search).oem || "");
        if (oem) {
            this.props.searchForProducts("chk-oem", qs.parse(this.props.location.search).oem);
            addedState.shouldRedirect = true;
        }
        this.setState({searchTerm: lastSearch, search: checkChoice, ...addedState})
    }

    /**
     * Event handler that removes event handlers on component close
     */
    componentWillUnmount() {
        window.removeEventListener('keypress', this.listenToEnter)
    }

    /**
     * Function that redirects to the details page
     * @param id id of the product to display
     */
    redirectToDetailsPage(id) {
        this.setState({...this.state, redirectId: id});
    }

    /**
     * Handler that changes the field on which to base the search
     * @param e Event
     */
    handleCheck = (e) => {
        this.setState({search: e.target.name})
    };

    /**
     * Render function that renders the searchbar
     * @returns {*}
     */
    renderResearchSection() {
        return <section className={"searchbar"}>
            <h2>Rechercher un produit</h2>
            <div className={"flex"}>
                <div>
                    <input tabIndex="0" type="text" id="searchInput" value={this.state.searchTerm} placeholder="Rechercher..."
                           onChange={(e) => {
                               if (!e.target.value) {
                                   this.setState({searchTerm: e.target.value}, this.props.searchForProducts)
                               } else {
                                   this.setState({searchTerm: e.target.value})
                               }
                           }}/>
                    <button
                        disabled={!this.state.searchTerm || (this.state.search === "chk-oem" && this.state.searchTerm.length < 3)}
                        onClick={this.searchProduct} tabIndex="-1">Rechercher
                    </button>
                </div>
                <div>
                    <span title={"Une recherche complexe peut comprendre : \n - Le caractère * (OU)" +
                    "\n - Le caractère + (ET) \n Ex. DO405 * RH414OS (trouve tous les produits dont l'oem contient " +
                    "\"DO405\" ou \"RH414OS\"). \n\n * Notez que la recherche limite à 2 le nombre de termes pour des questions de performance"
                    } className={'info-bubble'}>?</span>
                    <div id={"chk_choices"}>
                        <h3>Rechercher par :</h3>
                        <input name={"chk-oem"} checked={this.state.search === "chk-oem"} onChange={this.handleCheck}
                               type={"checkbox"} id={"chk-oem"}/>
                        <label htmlFor={"chk-oem"}>OEM</label>

                        <input name={"chk-sku"} checked={this.state.search === "chk-sku"} onChange={this.handleCheck}
                               type={"checkbox"} id={"chk-sku"}/>
                        <label htmlFor={"chk-sku"}>SKU</label>

                        <input name={"chk-descr"} checked={this.state.search === "chk-descr"}
                               onChange={this.handleCheck} type={"checkbox"} id={"chk-descr"}/>
                        <label htmlFor={"chk-descr"}>Description</label>
                    </div>
                </div>
            </div>
            <button tabIndex="-1" onClick={() => this.props.history.goBack()}>{'< Page précédente'}</button>
        </section>
    }

    /**
     * Sort function that uses the quantity of a product
     * @param a Product a
     * @param b Product b
     * @returns {number}
     */
    sortByQty(a, b) {
        if (isNaN(a.total_quantity)) return 1;
        if (isNaN(b.total_quantity)) return -1;
        return b.total_quantity - a.total_quantity
    }

    handleKeyPress(event, productId) {
        const {keyCode} = event;
        if (keyCode === 32 || keyCode === 13)
            this.redirectToDetailsPage(productId)
    }

    /**
     * Render funtion that renders a product line
     * @returns {Int16Array}
     */
    renderProductLine() {
        const {finished, searchTerm, search} = this.state;
        const {activeProducts, isLoading} = this.props;
        return activeProducts.length === 0 && !isLoading ? <tr>
                <td colSpan={6}><p>Lancez la recherche pour trouver un produit !</p></td>
            </tr> :
            isLoading ? <tr>
                    <td colSpan={6}><p>Chargement en cours...</p></td>
                </tr> :
                activeProducts.sort(this.sortByQty).map((p, i) => {
                    if (this.state.in_stock_only && p.total_quantity === 0) {
                        return null
                    }
                    return <tr tabIndex="0" className={p.isCreated ? "is-created" : ""} key={i}
                               onClick={() => this.redirectToDetailsPage(p.id)} onKeyDown={(e) => this.handleKeyPress(e, p.id)}>
                        <td>{i + 1}</td>
                        <td>{p.oem}</td>
                        <td className={"collapsable"}>{finished && search === "chk-sku" ? <Highlight search={searchTerm}
                                                                                                     matchStyle={{backgroundColor: '#d7df23'}}>{p.sku.join(', ')}</Highlight> : p.sku.join(', ')} </td>
                        <td>{finished && search === "chk-descr" ? <Highlight search={searchTerm}
                                                                             matchStyle={{backgroundColor: '#d7df23'}}>{p.description}</Highlight> : p.description}</td>
                        <td className={"center"}>{p.total_quantity}</td>
                    </tr>
                })
    }

    /**
     * Render function that renders the product list table
     * @returns {*}
     */
    renderProductsTable() {
        let headers = ["#", "OEM", "SKUs", "Description", "Quantité en Stock"];
        return <Fragment>
            <span>
                <input tabIndex="-1" name={"chk-en-stock"} checked={this.state.in_stock_only} onChange={() => {
                    this.setState({in_stock_only: !this.state.in_stock_only})
                }
                } type={"checkbox"} id={"chk-en-stock"}/>
                <label htmlFor={"chk-en-stock"}>En stock seulement</label>
            </span>
            <Table className={"prodList"} headers={headers} isClickable={true} centeredIndexes={[4]}>
                {this.renderProductLine()}
            </Table>
        </Fragment>
    }

    /**
     * Function that validates if a product is found
     * @returns {boolean} true if an exact match is found
     */
    foudExactMatchId = () => {
        const {activeProducts} = this.props;
        let oem = decodeURIComponent(qs.parse(this.props.location.search).oem || "");
        if (oem) {
            let foundProds = activeProducts.filter(p => p.oem.toLowerCase() === oem.toLowerCase());
            return foundProds.length > 0 ? foundProds[0].id : false;
        }
        return false;
    };

    /**
     * Main render function
     * @returns {*}
     */
    render() {
        if (this.state.redirectId) {
            return <Redirect to={"/products/" + this.state.redirectId} push/>
        }

        if (this.state.shouldRedirect && this.props.activeProducts.length === 1) {
            return <Redirect to={"/products/" + this.props.activeProducts[0].id + "?xml=true"}/>
        } else if (this.state.shouldRedirect && this.foudExactMatchId()) {
            return <Redirect to={"/products/" + this.foudExactMatchId() + "?xml=true"}/>
        }

        return <div className={"borne"}>
            {this.renderResearchSection()}
            {this.renderProductsTable()}
        </div>
    }
}

const mapDispatchToProps = dispatch => ({
    searchForProducts: (searchField, term) => dispatch(searchForProducts(searchField, term)),
    saveSearch: (searchField, checkChoice) => dispatch(saveSearch(searchField, checkChoice))
});

const mapStateToProps = (state) => {
    return {
        products: state.products.allProducts || [],
        activeProducts: state.products.activeProducts || [],
        productsAreLoading: state.products.isLoading,
        activeHeader: state.productList.activeHeader,
        isLoading: !state.products.isDoneLoading,
        lastSearch: state.productList.lastSearch,
        checkChoice: state.productList.checkChoice
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductsList));