import {baseUrl} from "../utils/constants";
import axios from "axios";
import {getToken} from "./authApi";

/**
 * Sends a request to the API to get all new and expired manufacturer correspondences
 * @returns {Object}
 */
export const getEmptyManufacturersRequest = async () => {
    let token = await getToken();

    const res = await axios.get(baseUrl + "/empty-manufacturers", {
        headers: {
            "Authorization": token
        }
    });
    return res.data;
};

/**
 * Sends a request to the API to update one or more manufacturer correspondences
 * @param modifications Modifications to apply
 * @returns {Object}
 */
export const updateEmptyManufacturersRequest = async (modifications) => {
    let token = await getToken();

    await axios.put(baseUrl + "/empty-manufacturers", modifications, {
        headers: {
            "Authorization": token
        }
    });
};

/**
 * Sends a request to the API to delete a manufacturer correspondence
 * @param idCorr Id of the correspondence to delete
 * @returns {Object}
 */
export const deleteManufCorrespondence = async (idCorr) => {
    let token = await getToken();

    await axios.delete(baseUrl + "/empty-manufacturers/" + idCorr, {
        headers: {
            "Authorization": token
        }
    });
};

export const getExcelExport = async (callback) => {
    let token = await getToken();
    let xhttp = new XMLHttpRequest();

    xhttp.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
            callback(xhttp.response)
        }
    };

    xhttp.open("GET", baseUrl + "/manufacturer-correspondences/extractToExcel", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authorization", token);
    xhttp.setRequestHeader('Cache-Control', 'no-cache');
    xhttp.responseType = "blob";
    xhttp.send();
};
