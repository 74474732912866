import React from 'react';
import {withRouter} from "react-router-dom";
import manufacturers from '../images/icons8-manufacturing-100.png';
import products from '../images/icons8-product-100.png';
import bids from '../images/icons8-terms-and-conditions-100.png';

/**
 * React component Landing page
 */
class Landing extends React.Component {

    /**
     * Render function
     * @returns {*}
     */
    render() {
        return <div className="App">
            <div className="landing-page">
                <div id={"sec-products"} className={"card"}>
                    <div className={'card clear'}>
                        <img src={products} alt={'icon products'}/>
                        <h2>Produits</h2>
                    </div>
                    <div className={'align-left caps bold'}>
                        <p>1. Obtenir des informations sur un produit.</p>
                        <p>2. Vérifier la disponibilité d'un produit.</p>
                        <p>3. Créer un produit dans NetSuite.</p>
                    </div>
                    <button onClick={() => this.props.history.push('/products')}>Voir les produits</button>
                </div>
                <div id={"sec-manufacturers"} className={"card"}>
                    <div className={'card clear'}>
                        <img src={manufacturers} alt={'icon manufacturers'}/>
                        <h2>Manufacturiers</h2>
                    </div>
                    <div className={'align-left caps bold'}>
                        <p>1. Faire la gestion des manufacturiers.</p>
                    </div>
                    <button onClick={() => this.props.history.push('/manufacturers-manager')}>Voir les manufacturiers
                    </button>
                </div>
                <div id={"sec-bids"} className={"card"}>
                    <div className={'card clear'}>
                        <img src={bids} alt={'icon bids'}/>
                        <h2>Bids</h2>
                    </div>
                    <div className={'align-left caps bold'}>
                        <p>1. Ajouter un nouveau BID.</p>
                        <p>2. Obtenir des informations sur les BIDs existants.</p>
                    </div>
                    <button onClick={() => this.props.history.push('/bid')}>Voir les BIDs</button>
                </div>
            </div>
        </div>
    }
}

export default withRouter(Landing);