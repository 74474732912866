import {addBidRequest, getBidsRequest, removeBidRequest} from "../api/bidApi";

export const GET_BIDS_REQUEST = "GET_BIDS_REQUEST";
export const GET_BIDS_SUCCESS = "GET_BIDS_SUCCESS";
export const GET_BIDS_ERROR = "GET_BIDS_ERROR";

export const ADD_BID_REQUEST = "ADD_BID_REQUEST";
export const ADD_BID_SUCCESS = "ADD_BID_SUCCESS";
export const ADD_BID_ERROR = "ADD_BID_ERROR";

export const DELETE_BID_REQUEST = "DELETE_BID_REQUEST";
export const DELETE_BID_SUCCESS = "DELETE_BID_SUCCESS";
export const DELETE_BID_ERROR = "DELETE_BID_ERROR";

/**
 * Action that requests bids from the api
 * @returns {Function}
 */
export const getBids = () => async dispatch => {
    try {
        dispatch({type: GET_BIDS_REQUEST});

        let data = await getBidsRequest();
        dispatch({
            type: GET_BIDS_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({type: GET_BIDS_ERROR, payload: e.message});
    }
};

/**
 * Action that adds a Bid to the Catalog
 * @param file Excel file to add
 * @returns {Function}
 */
export const addBid = (file) => async dispatch => {
    try {
        dispatch({type: ADD_BID_REQUEST});
        let data = await addBidRequest(file);
        dispatch({
            type: ADD_BID_SUCCESS,
            payload: data
        });
        dispatch(getBids());
    } catch (e) {
        dispatch({type: ADD_BID_ERROR, payload: e.message});
    }
};

/**
 * Action that deletes a Bid from the Catalog
 * @param bid_id Id of the bid to delete
 * @returns {Function}
 */
export const deleteBid = (bid_id) => async dispatch => {
    try {
        dispatch({type: DELETE_BID_REQUEST});
        await removeBidRequest(bid_id);
        dispatch({
            type: DELETE_BID_SUCCESS
        });
        dispatch(getBids());
    } catch (e) {
        dispatch({type: DELETE_BID_ERROR, payload: e.message});
    }
};
