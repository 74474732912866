import {combineReducers} from 'redux';
import productReducer from './productReducer';
import productListReducer from './productsListReducer';
import manufacturerReducer from './manufacturersReducer';
import bidReducer from './bidReducer';

export default combineReducers({
    products: productReducer,
    productList: productListReducer,
    manufacturers: manufacturerReducer,
    bids: bidReducer
});