import React from 'react'
import {withRouter} from "react-router";
import {getPNAForProduct, getProductDetails} from "../actions/productActions";
import {connect} from "react-redux";
import refresh from "../images/refresh.png"
import Loading from "./Loading";
import {DH_PROVIDER_NAME, INGRAM_PROVIDER_NAME, SYNNEX_PROVIDER_NAME, TECH_PROVIDER_NAME} from "../utils/constants";
import Table from "./Table";
import {NETSUITE_DOMAIN} from "../utils/constants";

/**
 * React component that renders the product details view
 */
class ProductsDetails extends React.Component {

    /**
     * Constructor function
     */
    constructor(props) {
        super(props);
        this.renderTableLine = this.renderTableLine.bind(this);
        this.renderProvidersSection = this.renderProvidersSection.bind(this);
        this.addToNetSuite = this.addToNetSuite.bind(this);
        this.renderButtonsRow = this.renderButtonsRow.bind(this);
        this.renderContractsSection = this.renderContractsSection.bind(this);
        this.createProductInNetsuite = this.createProductInNetsuite.bind(this);
        this.getCorrectWarehouse = this.getCorrectWarehouse.bind(this);
        this.renderVendorParameterString = this.renderVendorParameterString.bind(this);

        this.state = {
            activeSku: null,
            activeWarehouse: null,
            activeBID: null
        };
        this.getPNA = false;
        this.lastUpdate = null;
    }

    /**
     * Event handler that gets the products details when the component is ready
     */
    componentDidMount() {
        this.props.getProductDetails(this.props.match.params.id);
        this.getPNA = true;
    }

    /**
     * Render function that renders the top product details table (General info table)
     * @returns {*}
     */
    renderTableLine() {
        const {product} = this.props;
        const {activeSku} = this.state;
        let sku;
        if (activeSku) {
            sku = product.skus.filter(s => s.sku === activeSku)[0];
        }

        let tableHeaders = ["OEM", "Description", "Manufacturier", "En Stock"];
        return <Table className={"prodInfo"} headers={tableHeaders} isClickable={false} centeredIndexes={[2, 3]}>
            <tr>
                <td>{product.oem}</td>
                <td>{sku ? sku.description : product.description}</td>
                <td className={"center"}>{product.manufacturer}</td>
                <td className={"center"}>{product.skus.map(s => {
                    if (!isNaN(s.totalQuantity)) {
                        return parseInt(s.totalQuantity)
                    }
                    return null;
                }).reduce((total, num) => total + num)}</td>
            </tr>
        </Table>
    }

    /**
     * Function that parses and finds the correct warehouse name based on Netsuite's warehouse list
     * @param warehouseId Vendor id for the warehouse
     * @param warehouseName Vendor name for the warehouse
     * @param vendorName Name of the vendor
     * @returns {string}
     */
    getCorrectWarehouse(warehouseId, warehouseName, vendorName) {
        let returnString = '';
        switch (vendorName) {
            case SYNNEX_PROVIDER_NAME:
                if (warehouseName.includes('Markham')) {
                    returnString += `SYNNEX 57 - Markham, ON`;
                } else if (warehouseName.includes('Guelph')) {
                    returnString += `SYNNEX 29 - Guelph, ON`;
                } else if (warehouseName.includes('Halifax')) {
                    returnString += `SYNNEX 26 - Halifax, NS`;
                } else if (warehouseName.includes('Calgary')) {
                    returnString += `SYNNEX 31 - Calgary, AB`;
                } else if (warehouseName.includes('Richmond')) {
                    returnString += `SYNNEX 60 - Richmond, BC`;
                } else {
                    returnString += `SYNNEX ${warehouseId} - ${warehouseName}`
                }
                break;
            case INGRAM_PROVIDER_NAME:
                if (warehouseName.includes('Toronto')) {
                    returnString += `INGRAM 10 - ${warehouseName}, ON`;
                } else {
                    returnString += `INGRAM 40 - ${warehouseName}, BC`;
                }
                break;
            case TECH_PROVIDER_NAME:
                returnString += `TECH DATA - ${warehouseName.charAt(0) + warehouseName.slice(1, warehouseName.indexOf(',')).toLowerCase() + warehouseName.slice(warehouseName.indexOf(','))}`;
                break;
            case DH_PROVIDER_NAME:
                if (warehouseName.includes('Toronto')) {
                    returnString += `D&H - ${warehouseName}, ON`;
                } else {
                    returnString += `D&H - ${warehouseName}, BC`;
                }
                break;
            default:
                returnString += "";
        }
        return returnString;
    }

    /**
     * Function that sends a product info via window.opener
     * @param e Event
     */
    addToNetSuite(e) {
        e.stopPropagation();

        console.log("function was called");
        const {product} = this.props;
        const {activeSku, activeWarehouse, activeBID} = this.state;
        let sku = product.skus.filter(s => s.sku === activeSku)[0];
        let warehouse = sku.warehouses.filter(w => w.warehouseId === activeWarehouse)[0];
        let bid = sku.bids.filter(b => b.noBid === activeBID)[0];

        console.log("Data was found");
        let messageData = {
            "custcoloem_oride": product.oem,
            "quantity": 1,
            "rate": parseFloat(sku.msrp),
            "manufacturier": product.manuId ? product.manuId : null,
            "custcolpo_rate": parseFloat(bid ? bid.netPrice : sku.rate),
            "custcol_warehouse": this.getCorrectWarehouse(warehouse.warehouseId, warehouse.warehouseName, sku.vendor),
            "custcol_bid_number": activeBID,
            "custcol_group_ditems": null,
            "description": sku ? sku.description : product.description,
            "custcol_vendor_sku": activeSku,
            "custcol_mlgc_vendor": sku.vendorNumber,
            "weight": product.weight,
            "item": product.internalId
        };

        window.opener.postMessage({
            "event_id": "my_cors_message",
            data: messageData
        }, NETSUITE_DOMAIN);

        console.log("Message was posted");
    }

    /**
     * Function that constructs the vendor url parameter
     * @returns {string}
     */
    renderVendorParameterString() {
        const {product} = this.props;
        const {activeSku, activeBID} = this.state;
        let sku = product.skus.filter(s => s.sku === activeSku)[0];
        let bid = sku.bids.filter(b => b.noBid === activeBID)[0];

        let formatVendor = (vendorNumber, sku, price) => {
            return `${vendorNumber}:${sku}:${price}`;
        };

        let returnString = formatVendor(sku.vendorNumber, sku.sku, bid ? bid.netPrice : sku.rate);

        let otherSkus = product.skus.filter(s => s.sku !== activeSku)
            .filter(sku => product.skus.filter(s => s.vendorNumber === sku.vendorNumber).length === 1);

        otherSkus.forEach(productSku => {
            returnString += "|" + formatVendor(productSku.vendorNumber, productSku.sku, productSku.rate);
        });

        return returnString;
    }

    /**
     * Function that opens a new window to Netsuite to create a new product based on url parameters
     * @param e Event
     */
    createProductInNetsuite(e) {
        e.stopPropagation();
        const {product} = this.props;
        const NETSUITE_BASE_URL = `${NETSUITE_DOMAIN}/app/common/item/item.nl?itemtype=InvtPart&subtype=&isserialitem=T&islotitem=F`;

        const fields = ["vendor", "manuf", "salesdescription", "weight", "name", "itemid"];
        let url = "";
        for (let i = 0; i < fields.length; i++) {
            url += "&" + fields[i] + "=";

            switch (fields[i]) {
                case "vendor":
                    url += encodeURIComponent(this.renderVendorParameterString());
                    break;
                case "manuf":
                    url += encodeURIComponent(product.manufacturer);
                    break;
                case "salesdescription":
                    url += encodeURIComponent(product.description);
                    break;
                case "weight":
                    url += encodeURIComponent(product.weight);
                    break;
                case "name":
                    url += encodeURIComponent(product.oem);
                    break;
                case "itemid":
                    url += encodeURIComponent(this.props.match.params.id);
                    break;
                default:
                    break;
            }
        }

        window.open(NETSUITE_BASE_URL + url, 'Item Creation', "height=800,width=800,modal=yes,alwaysRaised=yes");
    }

    /**
     * Function that renders the add, create and refresh buttons
     * @returns {*}
     */
    renderButtonsRow() {
        const {activeSku, activeWarehouse} = this.state;
        const {product} = this.props;

        return <div className={'flex netActionButtons'}>
            <button onClick={() => this.props.history.goBack()}>{'< Page précédente'}</button>
            <div>
                <button disabled={!(activeSku && activeWarehouse) || window.opener == null || window.opener.closed}
                        onClick={this.addToNetSuite}>Ajouter
                </button>
                {!product.internalId ? <button disabled={!activeSku || !activeWarehouse}
                                               onClick={this.createProductInNetsuite}>Créer</button> : null}
                <button onClick={(e) => {
                    e.stopPropagation();
                    this.props.getPNAForProduct();
                    this.setState({lastUpdate: new Date()})
                }}>XML<img src={refresh} alt={"refresh icon"}/></button>
            </div>
        </div>
    }

    /**
     * Sort function that uses a sku
     * @param a Sku a
     * @param b Sku b
     * @returns {number}
     */
    compareFct = (a, b) => {
        let key = 'quantity';
        if ('totalQuantity' in a)
            key = 'totalQuantity';

        let aQty = parseInt(a[key], 10);
        let bQty = parseInt(b[key], 10);

        if (aQty < bQty) {
            return 1;
        }
        if (aQty > bQty) {
            return -1;
        }
        return 0;
    };

    /**
     * Gets the last PNA update hour of a sku
     * @param sku sku to verify
     * @returns {string}
     */
    getLastUpdateHour(sku) {
        const {updated} = this.props;
        const {lastUpdate} = this.state;

        let thisMorning = "6:00";
        let now = null;
        if (lastUpdate || this.lastUpdate) {
            now = (lastUpdate || this.lastUpdate).getHours() + ":" +
                ((lastUpdate || this.lastUpdate).getMinutes() < 10 ? '0' : '') +
                (lastUpdate || this.lastUpdate).getMinutes();
        }
        return updated ? updated.indexOf(sku.sku) > -1 ? now : thisMorning : thisMorning
    }

    /**
     * Renders the vendors table
     * @returns {*}
     */
    renderVendorsTable() {
        const {product, pnaLoading} = this.props;
        const {activeSku} = this.state;


        let tableVendorHeaders = ["#", "Nom Fourn.", "SKU", "Prix Coûtant", "MSRP", "Qté Stock", "Dernière MAJ"];
        if (!activeSku) {
            this.setState({ activeSku: product.skus[0].sku });
        }
        return <div>
            <h2>Fournisseurs</h2>
            <Table headers={tableVendorHeaders} isClickable={true} centeredIndexes={[0, 3, 4, 5, 6]}>
                {product.skus ? product.skus.sort(this.compareFct).map((sku, i) => {
                        let skuIndex = pnaLoading.findIndex(l => l.sku === sku.sku);
                        let isLoading = skuIndex > -1 && pnaLoading[skuIndex].loading;
                        return <tr onClick={(e) => {
                            e.stopPropagation();
                            this.setState({activeSku: sku.sku, activeWarehouse: null})
                        }} className={activeSku === sku.sku ? "selected" : ""} key={sku.sku}>
                            <td>{i + 1}</td>
                            <td>{sku.vendor}</td>
                            <td>{sku.sku}</td>
                            <td>{sku.rate.replace(/\d(?=(\d{3})+\.)/g, '$&,') + " $"}</td>
                            <td>{sku.msrp ? sku.msrp.replace(/\d(?=(\d{3})+\.)/g, '$&,') + " $" : "-"}</td>
                            <td>{sku.totalQuantity}</td>
                            <td>{isLoading ? <Loading/> : this.getLastUpdateHour(sku)}</td>
                        </tr>
                    }
                ) : null}
            </Table>
        </div>
    }

    /**
     * Renders the warehouse table
     * @param warehouses warehouses to display
     * @returns {*}
     */
    renderWarehousesTable(warehouses) {
        const {activeSku, activeWarehouse} = this.state;
        let tableWarehousesHeaders = ["#", "Nom Fourn.", "Location", "Qté Dispo"];
        if (!activeWarehouse && warehouses[0]?.warehouses[0]) {
            this.setState({ activeWarehouse: warehouses[0].warehouses.sort(this.compareFct)[0].warehouseId });
        }
        return <div>
            <h2>Détails distributeur</h2>
            <Table headers={tableWarehousesHeaders} centeredIndexes={[0, 3]} isClickable={true}>
                {warehouses && warehouses.length > 0 ? warehouses.map((warehouse) => {
                        if (warehouse.warehouses.length === 0) {
                            return <tr key={warehouse.sku}>
                                <td colSpan={4}>Aucune donnée à afficher. Lancez une requête de P&A pour
                                    vérifier les quantitées.
                                </td>
                            </tr>
                        }

                        return warehouse.warehouses.sort(this.compareFct).map((w, i) => {
                            return <tr
                                className={activeWarehouse === w.warehouseId && warehouse.sku === activeSku ? "selected" : ""}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({activeWarehouse: w.warehouseId})
                                }} key={w.warehouseId}>
                                <td>{++i}</td>
                                <td>{warehouse.vendor + " - " + warehouse.sku}</td>
                                <td>{this.getCorrectWarehouse(w.warehouseId, w.warehouseName, warehouse.vendor)}</td>
                                <td>{w.quantity}</td>
                            </tr>
                        })
                    }
                ) : <tr>
                    <td colSpan={4}>Veuillez sélectionner un vendeur pour voir ses entrepôts.</td>
                </tr>}
            </Table>
        </div>
    }

    /**
     * Render function that renders the vendors table
     * @returns {*}
     */
    renderProvidersSection() {
        const {product} = this.props;
        const {activeSku} = this.state;
        let warehouses = product.skus.filter(s => s.sku === activeSku);

        if (warehouses.length > 0) {
            warehouses = warehouses.map(s => {
                return {
                    vendor: s.vendor,
                    sku: s.sku,
                    warehouses: s.warehouses
                }
            });
        }

        return <section>
            <div className={"flex provider-flex"}>
                {this.renderVendorsTable()}
                {this.renderWarehousesTable(warehouses)}
            </div>
        </section>
    }

    /**
     * Function that launches an XML PNA refresh
     */
    getPNAAuto = () => {
        this.props.getPNAForProduct();
        this.lastUpdate = new Date();
        this.getPNA = false;
    };

    /**
     * Render function that renders the Bid table
     * @returns {*}
     */
    renderContractsSection() {
        const {product} = this.props;
        const {activeSku, activeBID} = this.state;
        let bids = null;
        if (activeSku) {
            bids = product.skus.filter(s => s.sku === activeSku)[0]?.bids;
        }
        let tableHeaders = ["#", "Nom du BID", "No. BID", "Qté Max", "Date début", "Expiration", "Escompte", "Prix net"];
        return <section>
            <h2>BID Disponible</h2>
            <Table headers={tableHeaders} isClickable={true} centeredIndexes={[0, 2, 3, 4, 5, 6, 7]}>
                {activeSku ? bids && bids.length > 0 ? bids.sort((a, b) => parseInt(b.revision) - parseInt(a.revision)).map((b, i) =>
                    <tr key={b.noBid + b.revision} className={activeBID === b.noBid ? "selected" : ""}
                        onClick={(e) => {
                            e.stopPropagation();
                            this.setState({activeBID: b.noBid})
                        }}>
                        <td>{i + 1}</td>
                        <td>{b.bidName}</td>
                        <td className={"center"}>{b.noBid}</td>
                        <td className={"center"}>{b.maxQty}</td>
                        <td className={"center"}>{b.startDate}</td>
                        <td className={"center"}>{b.endDate}</td>
                        <td className={"center"}>{b.refund + ' %'}</td>
                        <td className={"center"}>{b.netPrice + ' $'}</td>
                    </tr>) : <tr>
                    <td colSpan={8}>Aucun BID relié à ce sku</td>
                </tr> : <tr>
                    <td colSpan={8}>Veuillez sélectionner un fournisseur</td>
                </tr>}
            </Table>
        </section>
    }

    /**
     * Main render function
     * @returns {*}
     */
    render() {
        const {product, isLoading, error} = this.props;
        if (error) {
            return <p>An error occured, please contact support for more information.</p>
        }

        if (isLoading || !product) {
            return <p>Chargement...</p>
        }

        if (product && this.getPNA) {
            this.getPNAAuto()
        }

        return <div className={"borne"} onClick={() => this.setState({activeSku: null, activeWarehouse: null})}>
            <div className={"flex flex-ends"}>
                <h2>Détail du produit <span>{product.oem + ", " + product.description}</span></h2>
                {product.internalId ? <h2>{`ML sku : ${product.ml_sku}`}</h2> : null}
            </div>
            {this.renderTableLine()}
            {this.renderButtonsRow()}
            {this.renderProvidersSection()}
            {this.renderContractsSection()}
        </div>
    }
}

const mapDispatchToProps = dispatch => ({
    getProductDetails: (oem) => dispatch(getProductDetails(oem)),
    getPNAForProduct: () => dispatch(getPNAForProduct())
});

const mapStateToProps = (state) => {
    return {
        products: state.products.allProducts || [],
        activeProducts: state.products.activeProducts || [],
        productsAreLoading: state.products.isLoading,
        activeHeader: state.productList.activeHeader,
        product: state.products.productDetails,
        isLoading: state.products.isLoading,
        pnaLoading: state.products.pnaLoading,
        error: state.products.error,
        updated: state.products.updated
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductsDetails));